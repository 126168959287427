import { getOrderlineDetails } from '@backmarket/http-api/src/api-specs-my-orders-api/endpoints'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export function useOrderlineDetails({
  // UUID or internal numerical ID
  orderlineId,
  options = { immediate: false },
}: {
  orderlineId: string
  options?: { immediate: boolean }
}) {
  return useHttpFetch(getOrderlineDetails, {
    pathParams: {
      orderlineId,
    },
    ...options,
  })
}
