<template>
  <RevModal
    closable
    :name="name"
    :title="
      i18n(translations.title, {
        mobileService: mobileServiceName,
      })
    "
    variant="confirmation"
  >
    <template #body>
      <div class="space-y-32">
        <RevInfoBlock
          :icon="IconInfo"
          :title="
            i18n(translations.text1, {
              mobileService: mobileServiceName,
            })
          "
          variant="info"
        />

        <p>
          {{ i18n(translations.text2) }}
        </p>

        <RevButton full-width="always" :to="externalUrl" variant="primary">
          {{
            i18n(translations.cta, {
              mobileService: mobileServiceName,
            })
          }}
        </RevButton>
      </div>
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import type { LinkExternal } from '@backmarket/http-api/src/api-models/LinkExternal'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevModal } from '@ds/components/Modal'
import { IconInfo } from '@ds/icons/IconInfo'

import { CARE_COMMON_MODALS_NAMES } from '../names'

import translations from './MobileServiceRedirectModal.translations'
import type { MobileServiceRedirectModalProps } from './MobileServiceRedirectModal.types'

const props = defineProps<MobileServiceRedirectModalProps>()

const i18n = useI18n()

const externalUrl: LinkExternal = {
  href: props.redirectionUrl,
  type: 'external',
}
const name = `${CARE_COMMON_MODALS_NAMES.mobileServiceRedirectModal}-${props.orderlineId}`
</script>
