import { createHttpEndpoint } from '../../utils'

import { type Order } from './order.types'

/**
 * Retrieve data for an order with its order lines
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/orders-internal-api/definition#tag/order/operation/get-order}
 */
export const getOrder = createHttpEndpoint<Order>({
  method: 'GET',
  operationId: 'staffGetOrders',
  path: '/bm/orders/staff/orders/:id',
})

export interface postOrderAskBillBodyParams {
  order_id: number
}

/**
 * Ask for an order bill.
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/orders-internal-api/definition#tag/order/operation/post-order-askbill}
 */
export const postOrderAskBill = createHttpEndpoint<
  void,
  postOrderAskBillBodyParams
>({
  method: 'POST',
  operationId: 'askBill',
  path: '/bm/client/:id/orders/askbill',
})
